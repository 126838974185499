import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styles from "./home.module.css";

import SideNav from "../SideNavigation/sideNav";
// import AboutUs from "../AboutUs/aboutUs";
import Services from "../Services/services";
import Reviews from "../Reviews/reviews";
import ProcessReviewWrapper from "../Wrapper/ProcessReview";
import ContactSection from "../ContactSection/contactSection";
import Hero from "../Hero/hero";
import MeetTheFounders from "../MeetTheFounders/meetTheFounders";

// onSubmitContactForm(event)=>{
//   if(event.preventDefault){
//     event.preventDefault();
//   }
//   let form = event.target;
//   const formData = {
//     name: form.elements['name'].value,
//     replyEmail: form.elements['replyEmail'].value,
//     message: form.elements['message'].value
//   }
//   fetch('https://zqodrp7c47.execute-api.us-west-2.amazonaws.com/Prod/send',{
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(formData)
//   }).then((response)=>{
//     if(response.ok){
//       this.setState({
//         showSuccess: true,
//         showFailure: false,
//       })
//     } else {
//       this.setState({
//         showFailure: true,
//         showSuccess: false
//       })
//     }
//   }, (response)=>{
//     this.setState({
//       showFailure: true,
//       showSuccess: false
//     })
//     console.log(response);
//   })
// }
function buildThresholdList() {
  let thresholds = [];
  let numSteps = 20;

  for (let i=1.0; i<=numSteps; i++) {
    let ratio = i/numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

const Home = ({ localization }) => {
  const homeRef = useRef(null);
  const aboutUsRef = useRef(null);
  const servicesRef = useRef(null);
  const reviewsRef = useRef(null);
  const contactRef = useRef(null);

  const [navItem, setNavItem] = useState("home");
  const [date, setDate] = useState(Date.now);
  const [showSuccess, setSuccess] = useState(false);
  const [showFailure, setFailure] = useState(false);
  const [homeOpac, setHomeOpac] = useState(1);

  const onSubmitContactForm = event => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    let form = event.target;
    const formData = {
      name: form.elements["name"].value,
      replyEmail: form.elements["replyEmail"].value,
      message: form.elements["message"].value,
    };
    fetch("https://api.veselkaworks.com/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then(
      response => {
        if (response.ok) {
          setSuccess(true);
          setFailure(false);
        } else {
          setSuccess(false);
          setFailure(true);
        }
      },
      response => {
        setSuccess(true);
        setFailure(false);
        console.log(response);
      }
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, intObserver) => {
        entries.forEach(entity => {
          if (entity.isIntersecting) {
            // throttle to make smooth
            if (new Date() > date) {
              setDate(Date.now + 1000);
              setNavItem(entity.target.id);
            }
          }
        });
      },
      {
        threshold: 0.65,
      }
    );
    const fadeObserver = new IntersectionObserver(
      (entries, intObserver) => {
        entries.forEach(entity => {
          if(entity.isIntersecting){
            // throttle to make smooth
            if (new Date() > date) {
              setDate(Date.now + 200);
              entity.target.style.opacity = entity.intersectionRatio;
              setHomeOpac(entity.intersectionRatio);
            }
          }
        });
      },
      {
        threshold: buildThresholdList(),
      }
    );

    fadeObserver.observe(homeRef.current);

    observer.observe(homeRef.current);
    observer.observe(aboutUsRef.current);
    observer.observe(servicesRef.current);
    observer.observe(reviewsRef.current);
    observer.observe(contactRef.current);
  });

  return (
    <div className={styles.overflowhidden}>
      <div className={styles.sideNavWrapper}>
        <SideNav localization={localization} selectedNavItem={navItem} />
      </div>
      <section id={"home"} ref={homeRef}>
        <Hero localization={localization} />
      </section>
      <section id={"aboutus"} ref={aboutUsRef}>
        <MeetTheFounders localization={localization} />
      </section>
      <section id={"services"} ref={servicesRef}>
        <Services localization={localization} />
      </section>
      <section id={"reviews"} ref={reviewsRef}>
        <ProcessReviewWrapper>
          <Reviews localization={localization} />
        </ProcessReviewWrapper>
      </section>
      <section id={"contact"} ref={contactRef}>
        <ContactSection
          localization={localization}
          onSubmit={onSubmitContactForm}
          success={showSuccess}
          failure={showFailure}
        />
      </section>
    </div>
  );
};
Home.propTypes = {};

export default Home;
