import React from "react";
import PropTypes from "prop-types";

import Img from "gatsby-image";
import styles from "./reviewCard.module.css";

const ReviewCard = ({ imageData, text, title }) => (
  <div className={styles.reviewCardWrapper}>
    <Img fixed={imageData} className={styles.imageWrapper} />
    <div className={styles.quoteWrapper}>
      <span>{title}</span>
      <p>{text}</p>
    </div>
  </div>
);

export default ReviewCard;
