import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import styles from "./founder.module.css";
import Img from "gatsby-image";

const Founder = ({ founderImg, selected, founderText, clickHandler, founderName  }) => {
  
  const selectedClassNames = selected ? styles.selectedFounderText : styles.founderText;
  const founderImageSelected = selected ? styles.founderImgSelected : styles.founderImg;
  return (
    <>
    <span onClick={()=>{clickHandler(founderName)}}>
      <Img
        fluid={founderImg}
        backgroundColor={`#0a0a0d`}
        className={founderImageSelected}
      />
    </span>
      <div className={selectedClassNames}>
          <div className={styles.filter}>
            <p className={styles.textOverrides}>
              {founderText}
            </p>
          </div>
      </div>
    </>
  )
};

export default Founder;
