import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import styles from "./services.module.css";
import ServiceCard from "./ServiceCard/serviceCard";

const Services = ({ localization }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "Middle Forest.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logo: file(relativePath: { eq: "sunk_ship_mock.png" }) {
          childImageSharp {
            fixed(width: 260) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        brand: file(relativePath: { eq: "cali_product.png" }) {
          childImageSharp {
            fixed(width: 260) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        internet: file(relativePath: { eq: "website_design_product.png" }) {
          childImageSharp {
            fixed(width: 260) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid;
      const logoImageData = data.logo.childImageSharp.fixed;
      const brandImageData = data.brand.childImageSharp.fixed;
      const internetImageData = data.internet.childImageSharp.fixed;
      return (
        <BackgroundImage
          fluid={imageData}
          backgroundColor={`#0a0a0d`}
          Tag={"section"}
          className={styles.servicesBG}
        >
          <ServiceCard
            title={localization.serviceLogo}
            text={localization.serviceLogoText}
            linkText={localization.learnMore}
            link={"#"}
            imageData={logoImageData}
          />
          <ServiceCard
            title={localization.serviceBrand}
            text={localization.serviceBrandText}
            linkText={localization.learnMore}
            link={"#"}
            imageData={brandImageData}
          />
          <ServiceCard
            title={localization.serviceDesignAndDevelopment}
            text={localization.serviceDesignAndDevelopmentText}
            linkText={localization.learnMore}
            link={"#"}
            imageData={internetImageData}
          />
        </BackgroundImage>
      );
    }}
  />
);

export default Services;
