import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";

const ProcessReviewWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "abstract_second_final.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        fluid={data.desktop.childImageSharp.fluid}
        Tag={"section"}
        style={{
          backgroundAttachment: "fixed",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: "200px",
          paddingBottom: "200px",
        }}
      >
        {children}
      </BackgroundImage>
    )}
  />
);

export default ProcessReviewWrapper;
