import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import styles from "./hero.module.css";

const Hero = ({ localization }) => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "hero_background.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const bgImage = data.background.childImageSharp.fluid;
      return (
        <BackgroundImage
          fluid={bgImage}
          backgroundColor={`#0a0a0d`}
          Tag={"section"}
          className={styles.herobg}
        >
          <div className={styles.textWrapper}>
            <h1 className={styles.HeroText}>
              <div className={styles.firstTexts}>
                {localization.heroText1}
                <br />
                {localization.heroText2}
              </div>
            </h1>
              <div className={styles.secondTexts}>
                {localization.heroText3}
              </div>
              <div className={styles.thirdTexts}>
                {localization.heroText4}  
                <br />
                <span className={styles.underline}>
                  {localization.heroText5}
                </span>
              </div>
              <div className={styles.fourthTexts}>
                {localization.heroText6}
                <br />
                {localization.heroText7}
                <br />
                {localization.heroText8}
              </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default Hero;
