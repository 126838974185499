import React, { useState } from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import styles from "./MeetTheFounders.module.css";
import Founder from "./founder/founder";

const MeetTheFounders = ({ localization }) => {
  // could move to useStaticQuery hook here but doesn't seem to matter
  const [selected, setSelected] = useState('jeremy');
  
  return(
    <StaticQuery
      query={graphql`
        query {
          bg: file(relativePath: {eq: "about_us_background.jpeg"}){
            childImageSharp{
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          jeremy: file(relativePath: { eq: "Jeremy_square.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          jason: file(relativePath: { eq: "Jason_square.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          katana: file(relativePath: { eq: "Katana_square.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ava: file(relativePath: { eq: "Ava_square.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          konig: file(relativePath: { eq: "Konig_square.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const bg = data.bg.childImageSharp.fluid;
        const jeremyImg = data.jeremy.childImageSharp.fluid;
        const jasonImg = data.jason.childImageSharp.fluid;
        const katanaImg = data.katana.childImageSharp.fluid;
        const avaImg = data.ava.childImageSharp.fluid;
        const konigImg = data.konig.childImageSharp.fluid;
        return (
          <BackgroundImage
            fluid={bg}
            Tag={"section"}
            className={styles.MeetTheFoundersbg}
          >
            <h1 className={styles.MeetTheFoundersText}>
                {localization.MeetTheFoundersText1}
              </h1>
              <div className={styles.founderWrapper}>
                <div className={styles.rowOne}>
                  <Founder
                    selected={selected==='jeremy'}
                    founderImg={jeremyImg}
                    founderText={localization.jeremyText}
                    clickHandler={setSelected}
                    founderName={'jeremy'}
                  />
                  <Founder
                  selected={selected==='konig'}
                    founderImg={konigImg}
                    founderText={localization.konigText}
                    clickHandler={setSelected}
                    founderName={'konig'}
                  />
                </div>
                <div className={styles.row}>
                  <Founder
                    selected={selected==='ava'}
                    founderImg={avaImg}
                    founderText={localization.avaText}
                    clickHandler={setSelected}
                    founderName={'ava'}
                  />
                  <Founder
                      selected={selected==='jason'}
                      founderImg={jasonImg}
                      founderText={localization.jasonText}
                      clickHandler={setSelected}
                      founderName={'jason'}
                    />
                </div>
                <div className={styles.row}>
                  <Founder
                    selected={selected==='katana'}
                    founderImg={katanaImg}
                    founderText={localization.katanaText}
                    clickHandler={setSelected}
                    founderName={'katana'}
                  />
                </div>
              </div>
          </BackgroundImage>
        );
      }}
    />
  )
};

export default MeetTheFounders;
