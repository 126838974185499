import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "./serviceCard.module.css";

const ServiceCard = ({ title, text, imageData, link, linkText }) => (
  <div className={styles.serviceCard}>
    <div className={styles.imageWrapper}>
      <Img fixed={imageData} className={styles.imgStyle} />
    </div>
    <h3>{title}</h3>
    <div className={styles.doubleUnderline}></div>
    <div className={styles.textWrapper}>
      <p>{text}</p>
    </div>
    {
      //
      //<div className={styles.linkWrapper}>
      //<a href={link}>{linkText}</a>
      //</div>
    }
  </div>
);

export default ServiceCard;
