import React from "react";
import PropTypes from "prop-types";

import styles from "./navItem.module.css";

const NavItem = ({ text, link, selected }) => (
  <li className={`${styles.listItem} ${selected ? styles.selectedli : ""}`}>
    <a className={styles.itemText} href={link}>
      <svg height={12} width={12} viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10"></circle>
      </svg>
      <span>{text}</span>
    </a>
  </li>
);

export default NavItem;
