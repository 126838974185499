import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import styles from "./reviews.module.css";
import ReviewCard from "./reviewCard/reviewCard";

const Reviews = ({ localization }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "Middle Forest.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        reviewOneImage: file(relativePath: { eq: "hofford_review.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        reviewTwoImage: file(relativePath: { eq: "elli_cline.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        reviewThreeImage: file(relativePath: { eq: "cody_review.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          fluid={imageData}
          backgroundColor={`#0a0a0d`}
          Tag={"section"}
          className={styles.reviewsSection}
        >
          <ReviewCard
            title={localization.reviewOneTitle}
            text={localization.reviewOneQuote}
            imageData={data.reviewOneImage.childImageSharp.fixed}
          />
          <ReviewCard
            title={localization.reviewTwoTitle}
            text={localization.reviewTwoQuote}
            imageData={data.reviewTwoImage.childImageSharp.fixed}
          />
          <ReviewCard
            title={localization.reviewThreeTitle}
            text={localization.reviewThreeQuote}
            imageData={data.reviewThreeImage.childImageSharp.fixed}
          />
        </BackgroundImage>
      );
    }}
  />
);

export default Reviews;
