import React from "react";
import PropTypes from "prop-types";

import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import styles from "./contactSection.module.css";

const ContactSection = ({ localization, onSubmit, success, failure }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bottom_forest.jpeg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          fluid={imageData}
          backgroundColor={`#0a0a0d`}
          Tag={"section"}
          className={styles.contactSection}
        >
          <div className={styles.contactFormWrapper}>
            <div className={styles.contactFormSide}>
              {!success && !failure && (
                <>
                  <h1 className={styles.contactSectionHeader}>
                    {localization.contactSectionHeader}
                  </h1>
                  <p>{localization.contactUsDescription}</p>
                  <div className={styles.doubleUnderline}></div>
                  <form
                    className={styles.contactForm}
                    action="#"
                    onSubmit={onSubmit}
                  >
                    <label htmlFor="name">{localization.nameInput}</label>
                    <br />
                    <input
                      type="text"
                      name="name"
                      autoComplete="name"
                      maxLength="36"
                      placeholder={localization.namePlaceHolder}
                      required
                    />
                    <br />
                    <label htmlFor="replyEmail">
                      {localization.emailInput}
                    </label>
                    <br />
                    <input
                      type="email"
                      placeholder={localization.emailPlaceHolder}
                      name="replyEmail"
                      autoComplete="email"
                      required
                    />
                    <br />
                    <label>
                      {localization.messageInput}
                      <br />
                      <textarea
                        name="message"
                        col="100"
                        rows="5"
                        className={styles.textArea}
                        required
                        maxLength={500}
                      />
                    </label>
                    <br />
                    <button type="submit">{localization.submitText}</button>
                  </form>
                </>
              )}
              {success && (
                <p className={styles.success}>{localization.success} </p>
              )}
              {failure && (
                <p className={styles.failure}>{localization.failure}</p>
              )}
            </div>
            <div className={styles.infoSide}>
              <h3>{localization.our_services_header}</h3>
              <ol>
                <li>{localization.services_logo}</li>
                <li>{localization.services_infographic_design}</li>
                <li>{localization.services_print_design}</li>
                <li>{localization.services_advertising}</li>
                <li>{localization.services_business_cards}</li>
                <li>{localization.services_letterheads}</li>
                <li>{localization.services_presentation_design}</li>
                <li>{localization.services_web_graphics}</li>
                <li>{localization.services_web_development}</li>
                <li>{localization.services_package_design}</li>
              </ol>
              <h3>{localization.information_header}</h3>
              <div className={styles.infoWrapper}>
                <span>{localization.general_inquiry}</span>
                <span>{localization.contact_email}</span>
                <span>{localization.jobContactInquiry}</span>
                <span>{localization.careerEmail}</span>
              </div>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default ContactSection;
