import React from "react";
import PropTypes from "prop-types";

import NavItem from "./NavItem/navItem";
import styles from "./sideNav.module.css";

const SideNav = ({ localization, selectedNavItem }) => (
  <nav>
    <ul className={styles.sideNav}>
      <NavItem
        text={localization.home}
        link={localization.homeHref}
        selected={selectedNavItem === "home"}
      />
      <NavItem
        text={localization.aboutus}
        link={localization.aboutusHref}
        selected={selectedNavItem === "aboutus"}
      />
      <NavItem
        text={localization.services}
        link={localization.servicesHref}
        selected={selectedNavItem === "services"}
      />
      {/*<NavItem text={localization.ourprocess} link={localization.ourprocessHref} selected={selectedNavItem === 'ourprocess'} />*/}
      <NavItem
        text={localization.reviews}
        link={localization.reviewsHref}
        selected={selectedNavItem === "reviews"}
      />
      <NavItem
        text={localization.contact}
        link={localization.contactHref}
        selected={selectedNavItem === "contact"}
      />
    </ul>
  </nav>
);

export default SideNav;
